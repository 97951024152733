.file-name[data-v-36453743] {
  color: #6f91c1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo[data-v-36453743] {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
}
.headInfo label[data-v-36453743] {
  width: 100px;
}